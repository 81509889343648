import React from 'react';
import CurrentStatus from "../CurrentStatus/CurrentStatus";
import Graph from "../Graph/Graph";
import PostGraph from "../Graph/PostGraph";
import TvrGraph from "../Graph/TvrGraph";
import ActiveChannelTable from "../Table/ActiveChannelTable";
import ActiveUserTable from "../Table/ActiveUserTable";
import Map from "../Map/Map";
import { useState, useEffect } from "react";
import axiosConfig from "../axiosConfig";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import Cookies from 'universal-cookie';
import { localeData } from 'moment/moment';
import { configure } from '@testing-library/react';
import axiosConfigDotNet from '../axiosConfigDotNet';


const OverviewOPT = () => {
    const cookies = new Cookies();
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");

    function pad(n) {
        return n < 10 ? '0' + n : n
    }

    if (cookies.get('_role') === "admin" || cookies.get('_role') === "operator") {
        var today = new Date(),
            role_datetime = today.getFullYear() + '-' + pad((today.getMonth() + 1)) + '-' + pad(today.getDate()) + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    } else if (cookies.get('_role') === "general" || cookies.get('_role') === "add-agency") {
        var last_updated_date = new Date(lastUpdatedDate);
        var role_datetime = last_updated_date.getFullYear() + '-' + pad((last_updated_date.getMonth() + 1)) + '-' + pad(last_updated_date.getDate()) + ' 23:59:59';
    }

    // console.log(new Date(lastUpdatedDate));
    // console.log(role_datetime);

    const [category, setCategory] = useState("Reach(000)");
    const [start, setStart] = useState("");
    const [finish, setFinish] = useState("");
    const [userType, setUserType] = useState("STB");
    //const [region, setRegion] = useState(["Dhaka", "Chattogram", "Rajshahi", "Sylhet", "Mymensingh", "Khulna", "Rangpur", "Barishal"]);
    const [region, setRegion] = useState(["Dhaka", "Chattogram"]);
    const [gender, setGender] = useState("");
    const [economic, setEconomic] = useState(["a", "b", "c", "d"]);
    // const [socio, setSocio] = useState("");
    const [ageGroup, setAgeGroup] = useState(["4-14", "15-24", "25-34", "35-44", "45 & Above"]);
    const [foreign, setForeign] = useState(false);
    const [err, setErr] = useState("error");
    const [loading, setloading] = useState(false);
    const [inputData, setInputData] = useState({});
    const [outputData, setOutputData] = useState(null);
    const [channelData, setChannelData] = useState({
        labels: [],
        datasets: []
    });


    const handleCheckboxChangeAgeGroup = (event) => {
        const value = event.target.value;
        setAgeGroup(prevState => {
            const newState = [...prevState];
            const index = newState.indexOf(value);
            if (index === -1) {
                newState.push(value);
            } else {
                newState.splice(index, 1);
            }
            return newState;
        });
    }

    const handleCheckboxChangeEconomic = (event) => {
        const value = event.target.value;
        setEconomic(prevState => {
            const newState = [...prevState];
            const index = newState.indexOf(value);
            if (index === -1) {
                newState.push(value);
            } else {
                newState.splice(index, 1);
            }
            return newState;
        });
    }

    
    const region_lock_date = new Date("2024-08-15T23:59:59").getTime();
    const isRegionDisabled = new Date(start).getTime() > region_lock_date || new Date(finish).getTime() > region_lock_date;

    useEffect(() => {
        if (isRegionDisabled) {
            setRegion(prevState => prevState.filter(value => value === "Dhaka" || value === "Chattogram"));
        }
    }, [isRegionDisabled]);

    const handleCheckboxChangeRegion = (event) => {
        const value = event.target.value;
        setRegion(prevState => {
            const newState = [...prevState];
            const index = newState.indexOf(value);
            if (index === -1) {
                newState.push(value);
            } else {
                newState.splice(index, 1);
            }
            return newState;
        });
    }


    useEffect(() => {

        axiosConfig.get("/data/cleanse/alldates").then((rsp) => {
            // setCleans(rsp.data.data);
            setLastUpdatedDate(rsp.data.lastUpdatedDate);

            if (rsp.data.lastUpdatedDate) {
                var last_cleaned_date = new Date(rsp.data.lastUpdatedDate),
                    default_start_cleanedDateWise = last_cleaned_date.getFullYear() + '-' + pad((last_cleaned_date.getMonth() + 1)) + '-01 00:00:00',
                    default_finish_cleanedDateWise = last_cleaned_date.getFullYear() + '-' + pad((last_cleaned_date.getMonth() + 1)) + '-' + pad(last_cleaned_date.getDate()) + ' 23:59:59';

                setStart(default_start_cleanedDateWise);
                setFinish(default_finish_cleanedDateWise);

                console.log("default_start_cleanedDateWise", default_start_cleanedDateWise);
                console.log("default_finish_cleanedDateWise", default_finish_cleanedDateWise);
            }

        }, (err) => { });

    }, [])



    const BasicchannelDownloadfunc = () => {
        //console.log(liveChannelData.labels[0]);
        if (inputData.userType == "") {
            var csv = [["Graph Category-", inputData.category], ["Universe-", parseInt(inputData.universe)], ["Sample-", inputData.sample], ["Audience/User Type-", "All_User_type"], ["Time Duration-", "Start-" + modify_date(inputData.start) + "_Finish-" + modify_date(inputData.finish)], ["", ""], ["Channel", "Value"]];
        } else if (inputData.userType == "OTT") {
            var csv = [["Graph Category-", inputData.category], ["Universe-", parseInt(inputData.universe)], ["Sample-", inputData.sample], ["Audience/User Type-", inputData.userType], ["Time Duration-", "Start-" + modify_date(inputData.start) + "_Finish-" + modify_date(inputData.finish)], ["", ""], ["Channel", "Value"]];
        } else if (inputData.userType == "STB") {
            var csv = [
                ["Graph Category-", inputData.category],
                ["Universe-", parseInt(inputData.universe)],
                ["Sample-", inputData.sample],
                ["Audience/User Type-", inputData.userType],
                ["Foreign-", inputData.foreign == true ? "Including Foreign" : "Excluding Foreign"],
                ["Gender-", (inputData.gender ? inputData.gender == "m" ? "Male" : "Female" : "All-Gender")],
                ["Time Duration-", "Start- " + modify_date(inputData.start) + " _ Finish- " + modify_date(inputData.finish)],
            ];

            var merged = ["Region-"].concat(
                inputData.region.map((market) => market.charAt(0).toUpperCase() + market.slice(1)).join(', '));
            csv.push(merged);

            var merged = ["Age Group:"].concat(inputData.ageGroup.join(', ').replace('4-14', ' 4-14'));
            csv.push(merged);

            //remove 'e' from sec in data.req_data.sec
            var sec = inputData.economic;
            if (sec.includes('e')) {
                sec.splice(sec.indexOf('e'), 1);
            }
            var merged = ["Economic:"].concat(
                sec?.map((sec) =>
                    sec === "a"
                        ? "SEC A"
                        : sec === "b"
                            ? "SEC B"
                            : sec === "c"
                                ? "SEC C"
                                : "SEC D & E"
                ).join(', ')
            );
            csv.push(merged);

            var merged = ["", ""];
            csv.push(merged);

            var merged = ["Channel", "Value"];
            csv.push(merged);

        }

        //var csv = [["Channel", "Value"]];
        var sampleLive = channelData;
        //console.log(sampleLive);
        for (var i = 0; i < sampleLive.labels.length; i++) {
            csv.push([sampleLive.labels[i], sampleLive.datasets[0].data[i]]);
        }
        //console.log(csv);
        getCSV(csv);
    }

    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    var getCSV = (scsv) => {

        exportToCsv("Basic_Report(" + inputData.category + ").csv", scsv)

    }

    // function compareObjects(obj1, obj2) {
    //     const keys1 = Object.keys(obj1).filter(key => key !== 'category');
    //     const keys2 = Object.keys(obj2).filter(key => key !== 'category');

    //     if (keys1.length !== keys2.length) {
    //         return false;
    //     }

    //     for (const key of keys1) {
    //         if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
    //             if (obj1[key].length !== obj2[key].length) {
    //                 return false;
    //             }
    //             const set1 = new Set(obj1[key]);
    //             const set2 = new Set(obj2[key]);
    //             if (set1.size !== set2.size) {
    //                 return false;
    //             }
    //             for (const val of set1) {
    //                 if (!set2.has(val)) {
    //                     return false;
    //                 }
    //             }
    //         } else if (obj1[key] !== obj2[key]) {
    //             return false;
    //         }
    //     }

    //     return true;
    // }



    const GetData = () => {
        // add e in economic id d already there
        var sec = [...economic];
        console.log(sec);
        if (sec.includes('d')) {
            sec.push('e');
        }

        var data = {
            category: category,
            start: start,
            finish: finish,
            userType: userType,
            region: region,
            gender: gender,
            economic: sec,
            foreign: foreign,
            ageGroup: ageGroup,
        };


        if (category !== "" && start !== "" && finish !== "" && economic.length > 0 && ageGroup.length > 0 && region.length > 0 && (new Date(start).getTime() <= new Date(role_datetime).getTime()) && (new Date(finish).getTime() <= new Date(role_datetime).getTime()) && (new Date(start).getTime() <= new Date(finish).getTime())) {
            setErr("");
            setloading(false);

            axiosConfigDotNet.post("/BasicReport/basic_report_all-universe", data).then(rsp => {
                setloading(true);
                setOutputData(rsp.data);
                console.log(rsp.data);
                //console.log(rsp.data);
                setChannelData(() => ({
                    labels: rsp.data.outputChannelName, datasets: [{
                        label: rsp.data.request.category, data: rsp.data.outputChannelValue,
                        backgroundColor: ["#2a71d0"],
                        //borderColor: "black",
                        borderWidth: 1,
                        categoryPercentage: 0.9,
                        barPercentage: 1
                    }]
                }));
                setInputData(rsp.data.request);
                console.log(rsp.data.request);
            }).catch(err => {

            });


        } else {
            setErr("error");
        }



    }

    var start_string = new Date(inputData.start).toLocaleString(undefined, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
    var finish_string = new Date(inputData.finish).toLocaleString(undefined, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });



    const modify_date = (date) => {

        return new Date(date).toLocaleString(undefined, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }

    

    return (
        <div>
            <Header title="Basic Reports" />
            <MainMenu menu="basicreports" />

            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper" style={{ backgroundColor: "azure" }} >
                    <div class="content-header row">
                    </div>
                    <div class="content-body">

                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <label>Graph Category</label>
                                            <select class="custom-select d-block w-100" onChange={(e) => { setCategory(e.target.value) }}>
                                                <option value="Reach(000)">Reach (000)</option>
                                                <option value="Reach(%)">Reach (%)</option>
                                                <option value="TVR(000)">TVR (000)</option>
                                                <option value="TVR(%)">TVR (%)</option>
                                                <option value="TVR Share(%)">TVR Share (%)</option>
                                                <option value="Time Spent-Universe(minute)">Time Spent (Uni)</option>
                                                {/* <option value="Time Spent-Viewer (uni-minute)">Time Spent -Viewer(Uni)</option> */}
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <label>Type (STB/OTT)</label>
                                            <select class="custom-select d-block w-100" disabled value={userType} onChange={(e) => { setUserType(e.target.value) }}>
                                                <option value="">All</option>
                                                <option value="STB">STB</option>
                                                <option value="OTT">OTT</option>

                                            </select>
                                        </div>

                                        {/* <div class="col-md-2">
                                            <label>Region</label>
                                            <select class="custom-select d-block w-100" disabled={userType == "OTT" || userType == ""} onChange={(e) => { setRegion(e.target.value) }}>
                                                <option value="">All Region</option>
                                                <option value="Dhaka">Dhaka</option>
                                                <option value="Chattogram">Chattogram</option>
                                                <option value="Rajshahi">Rajshahi</option>
                                                <option value="Sylhet">Sylhet</option>
                                                <option value="Mymensingh">Mymensingh</option>
                                                <option value="Khulna">Khulna</option>
                                                <option value="Rangpur">Rangpur</option>
                                                <option value="Barishal">Barishal</option>
                                            </select>
                                        </div> */}

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Region
                                                </label>
                                                <div className="border border-secondary p-1" >
                                                    <label className=""><input type="checkbox" value="Dhaka" checked={region.includes("Dhaka")} onChange={handleCheckboxChangeRegion} /> Dhaka </label>
                                                    <label className="ml-1"><input type="checkbox" value="Chattogram" checked={region.includes("Chattogram")} onChange={handleCheckboxChangeRegion} /> Chattogram </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="checkbox"
                                                            value="Rajshahi"
                                                            checked={region.includes("Rajshahi")}
                                                            onChange={handleCheckboxChangeRegion}
                                                            disabled={isRegionDisabled}
                                                        /> Rajshahi
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="checkbox"
                                                            value="Sylhet"
                                                            checked={region.includes("Sylhet")}
                                                            onChange={handleCheckboxChangeRegion}
                                                            disabled={isRegionDisabled}
                                                        /> Sylhet
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="checkbox"
                                                            value="Mymensingh"
                                                            checked={region.includes("Mymensingh")}
                                                            onChange={handleCheckboxChangeRegion}
                                                            disabled={isRegionDisabled}
                                                        /> Mymensingh
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="checkbox"
                                                            value="Khulna"
                                                            checked={region.includes("Khulna")}
                                                            onChange={handleCheckboxChangeRegion}
                                                            disabled={isRegionDisabled}
                                                        /> Khulna
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="checkbox"
                                                            value="Rangpur"
                                                            checked={region.includes("Rangpur")}
                                                            onChange={handleCheckboxChangeRegion}
                                                            disabled={isRegionDisabled}
                                                        /> Rangpur
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="checkbox"
                                                            value="Barishal"
                                                            checked={region.includes("Barishal")}
                                                            onChange={handleCheckboxChangeRegion}
                                                            disabled={isRegionDisabled}
                                                        /> Barishal
                                                    </label>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Age Group
                                                </label>
                                                <div className="border border-secondary p-1" >
                                                    <label className=""><input type="checkbox" value="4-14" checked={ageGroup.includes("4-14")} onChange={handleCheckboxChangeAgeGroup} /> 4-14 Yrs </label>
                                                    <label className="ml-1"><input type="checkbox" value="15-24" checked={ageGroup.includes("15-24")} onChange={handleCheckboxChangeAgeGroup} /> 15-24 Yrs</label>
                                                    <label className="ml-1"><input type="checkbox" value="25-34" checked={ageGroup.includes("25-34")} onChange={handleCheckboxChangeAgeGroup} /> 25-34 Yrs</label>
                                                    <label className="ml-1"><input type="checkbox" value="35-44" checked={ageGroup.includes("35-44")} onChange={handleCheckboxChangeAgeGroup} /> 35-44 Yrs</label>
                                                    <label className="ml-1"><input type="checkbox" value="45 & Above" checked={ageGroup.includes("45 & Above")} onChange={handleCheckboxChangeAgeGroup} /> 45 & Above Yrs</label>
                                                </div>
                                            </div>
                                        </div>



                                        {/* <div class="col-md-2">
                                            <div class="price-range">
                                                <div class="form-group">
                                                    <div class="slider-sm slider-success my-1" id="small-slider"></div>
                                                </div>
                                                <div class="price-slider">
                                                    <div class="price_slider_amount mb-2">
                                                        <div class="range-amt"><strong>Age Range : </strong> 1
                                                            - 100</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}


                                    </div>


                                    <div class="row" style={{ paddingTop: '25px' }}>


                                        {/* <div class="col-md-2">
                                            <label>SEC</label>
                                            <select class="custom-select d-block w-100" disabled={userType == "OTT" || userType == ""} onChange={(e) => { setEconomic(e.target.value) }}>
                                                <option value="">All SEC</option>
                                                <option value="a">SEC A</option>
                                                <option value="b">SEC B</option>
                                                <option value="c">SEC C</option>
                                                <option value="d">SEC D</option>
                                                <option value="e">SEC D</option>

                                            </select>
                                        </div> */}

                                        {/* <div class="col-md-2">
                                            <label>Gender</label>
                                            <select class="custom-select d-block w-100" disabled={userType == "OTT" || userType == ""} onChange={(e) => { setGender(e.target.value) }}>
                                                <option value="">All Gender</option>
                                                <option value="m">Male</option>
                                                <option value="f">Female</option>
                                            </select>
                                        </div> */}

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>
                                                    Gender
                                                </label>
                                                <div className="border border-secondary p-1">
                                                    <label className="">
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value=""
                                                            checked={gender === ""}
                                                            onChange={() => setGender("")}
                                                        />
                                                        All
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="m"
                                                            checked={gender === "m"}
                                                            onChange={() => setGender("m")}
                                                        />
                                                        Male
                                                    </label>
                                                    <label className="ml-1">
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="f"
                                                            checked={gender === "f"}
                                                            onChange={() => setGender("f")}
                                                        />
                                                        Female
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    SEC
                                                </label>
                                                <div className="border border-secondary p-1" >
                                                    <label className=""><input type="checkbox" value="a" checked={economic.includes("a")} onChange={handleCheckboxChangeEconomic} />SEC A</label>
                                                    <label className="ml-1"><input type="checkbox" value="b" checked={economic.includes("b")} onChange={handleCheckboxChangeEconomic} />SEC B</label>
                                                    <label className="ml-1"><input type="checkbox" value="c" checked={economic.includes("c")} onChange={handleCheckboxChangeEconomic} />SEC C</label>
                                                    <label className="ml-1"><input type="checkbox" value="d" checked={economic.includes("d")} onChange={handleCheckboxChangeEconomic} />SEC D</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Including/Excluding Channel
                                                </label>
                                                <div className="border border-secondary p-1" >
                                                    <label className="ml-1"><input type="checkbox" value={true} checked={foreign} onChange={(e) => setForeign(!foreign)} />Foreign</label>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <div class="col-md-2">
                                            <select class="custom-select d-block w-100" disabled={userType == "OTT" || userType == ""} onChange={(e) => { setSocio(e.target.value) }}>
                                                <option value="">Urban & Rural</option>
                                                <option value="u">Urban</option>
                                                <option value="r">Rural</option>
                                            </select>
                                        </div> */}


                                        <fieldset className="form-group form-group-style col-md-2">
                                            <label htmlFor="dateTime1">Start Time</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="dateTime1"
                                                step="60" // Set step to 60 for minutes
                                                value={start ? new Date(start + 'Z').toISOString().slice(0, -8) : ""} // Add 'Z' to indicate UTC
                                                onChange={(e) => { setStart(e.target.value + ':00') }}
                                            />
                                            <label className="text-opacity-20">mm/dd/yyyy h:m AM/PM</label>
                                        </fieldset>

                                        <fieldset className="form-group form-group-style col-md-2">
                                            <label htmlFor="dateTime2">Finish Time</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="dateTime2"
                                                step="60" // Set step to 60 for minutes
                                                value={finish ? new Date(finish + 'Z').toISOString().slice(0, -8) : ""} // Add 'Z' to indicate UTC
                                                onChange={(e) => { setFinish(e.target.value + ':59') }}
                                            />
                                            <label className="text-opacity-70" htmlFor="dateTime2">mm/dd/yyyy h:m AM/PM</label>
                                        </fieldset>

                                        <div class="col-md-2 ">
                                            <button onClick={GetData} class="btn btn-info">View</button>

                                        </div>
                                        {(() => {
                                            if (loading) {
                                                if (err !== "error") {
                                                    return (
                                                        <div class="col-md-2 ">
                                                            <button onClick={BasicchannelDownloadfunc} class="btn btn-danger">Download CSV</button>

                                                        </div>
                                                    )
                                                }

                                            }
                                        })()}

                                    </div>


                                </div>
                            </div>
                        </div>



                        <div class="row justify-content-md-center">
                            <div class="col">
                                {/* <PostGraph title="Active Users" text="Active Channels" url="reach/percent" label="Active Users" color="blue" credentials={credential} /> */}

                                {(() => {
                                    if (err === "error") {
                                        return (
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="h2 card-title text-warning font-weight-bold">Please enter a valid date until <span class="text-primary bold"> {modify_date(role_datetime)}</span> & other inputs also.</div>

                                                </div>
                                                <div class="card-body collapse show">


                                                </div>
                                            </div>
                                        )
                                    } else {

                                        if (loading) {
                                            return (
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="row"><div class="col-6 h2 card-title font-weight-bold">Channels {inputData.category} <p><small>Universe (000): {parseInt(inputData.universe)} <br></br> Sample: {inputData.sample}</small></p></div><div class="row col h2 card-title text-left">From [<p class="text-primary bold"> {start_string}</p>] to [<p class="text-primary bold">{finish_string}</p>] </div></div>

                                                    </div>
                                                    <div class="card-body collapse show" style={{ height: "35em" }}>


                                                        <Bar
                                                            data={channelData}
                                                            options={{
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                title: {
                                                                    display: true,
                                                                    text: "Channels",
                                                                    fontSize: 1
                                                                },
                                                                legend: {
                                                                    display: true,
                                                                    position: 'right'
                                                                }, plugins: {
                                                                    legend: {
                                                                        display: false  //remove if want to show label 
                                                                    }
                                                                }
                                                            }}
                                                        />


                                                    </div>
                                                </div>
                                            )


                                        } else {
                                            return (
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="row"><div class="col-6 h2 card-title font-weight-bold">Channels {category}</div></div>

                                                    </div>
                                                    <div class="card-body collapse show" style={{ height: "35em" }}>

                                                        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />


                                                    </div>
                                                </div>

                                            )

                                        }


                                    }
                                })()}



                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default OverviewOPT;