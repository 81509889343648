import React from "react";
import { useState, useEffect } from "react";
import axiosConfig from "../axiosConfig";
import Select from "react-select";
import Header from "../Header/Header";
import MainMenu from "../MainMenu/MainMenu";

const NoticeBoard = () => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send data to the server using Axios
      const response = await axiosConfig.post("/dashboard/notification-board", { title, details });
      console.log(response.data); // Assuming the server responds with the saved message data
      // Clear form fields after successful submission
      setTitle("");
      setDetails("");
      alert("Message submitted successfully!");
    } catch (error) {
      console.error("Error submitting message:", error);
      alert("An error occurred while submitting the message.");
    }
  };

  // const Seen = () => {

  //     axiosConfig.get("/dashboard/seennotification").then(rsp => {

  //     }).catch(err => {

  //     });

  //     axiosConfig.get("/dashboard/notification").then(rsp => {
  //         setNotifyNumber(rsp.data.notifyNumber)
  //         setNotifyData(rsp.data.data);
  //     }).catch(err => {

  //     });
  // }

  // useEffect(() => {
  //     axiosConfig.get("/dashboard/notification").then(rsp => {
  //         setNotifyNumber(rsp.data.notifyNumber)
  //         setNotifyData(rsp.data.data);
  //     }).catch(err => {

  //     });
  // }, []);

  return (
    <div>
      <Header title="NoticeBoard" />
      <MainMenu menu="NoticeBoard" />
      <div
        class="app-content content"
        style={{ backgroundColor: "azure", minHeight: "36em" }}
      >
        <div class="content-overlay"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body">
            <div class="row justify-content-md-center">
              <div class="col-xl-12  col-12">
                <section id="horizontal-vertical">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-content collapse show">
                          <div class="card-body card-dashboard">
                            <div>
                              <h2 style={{ textAlign: "center" }}>
                                Notice Board Message Form By Admin
                              </h2>
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-4">
                                    <label htmlFor="title">
                                      <b>Title:</b>
                                    </label>
                                    <input
                                      type="text"
                                      id="title"
                                      className="form-control"
                                      value={title}
                                      onChange={(e) => setTitle(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-md-8">
                                    <label htmlFor="details">
                                      {" "}
                                      <b>Details: </b>
                                    </label>
                                    <textarea
                                      id="details"
                                      className="form-control"
                                      value={details}
                                      onChange={(e) =>
                                        setDetails(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                                
                                    <button type="submit" className="btn btn-primary">Submit</button>
                              
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <br />

            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NoticeBoard;
