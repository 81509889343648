import React from 'react';
import { useState, useEffect } from "react";
import axiosConfig from "../../axiosConfig";
import Header from '../../Header/Header';
import MainMenu from '../../MainMenu/MainMenu';
import Cookies from 'universal-cookie';
import LiveMap from "./LiveMap";
import { useParams } from "react-router-dom";



const MapLocation = () => {
    const { user_id } = useParams();
    const cookies = new Cookies();

    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [userLocationHistory, setUserLocationHistory] = useState([]);
    const [user, setUser] = useState('');


    // const Search = (data) => {
    //     return data.filter(
    //         (item) =>
    //             item.device_name.toLowerCase().includes(query.toLowerCase()) ||
    //             item.deviceBoxId && item.deviceBoxId.toString().includes(query)
    //     );
    // };



    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    var getCSV = (scsv) => {
        exportToCsv(user.user_name + "App_User_Location_History" + new Date().toLocaleString() + ".csv", scsv)
    }

    const exportDevices = () => {
        //console.log(liveChannelData.labels[0]);
        var csv = [["Log ID", "Created_at", "Latitude", "Longitude"]];

        for (var i = 0; i < userLocationHistory.length; i++) {
            csv.push([
                userLocationHistory[i].id,
                userLocationHistory[i].title,
                userLocationHistory[i].lat,
                userLocationHistory[i].lng,
                // userLocationHistory[i].address ?? "Box Not Connected",


            ]);


        }
        console.log(csv);
        getCSV(csv);
    }

    useEffect(() => {
        axiosConfig.get("/user/map/" + user_id).then(rsp => {
            setUserLocationHistory(rsp.data.appUserLocationHistory);
            setUser(rsp.data.user);
            console.log(rsp);
            setLoading(true);



        }).catch(err => {
            setLoading(true);
        });

    }, []);


    const modify_date = (date) => {

        return new Date(date).toLocaleString(undefined, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    }


    return (
        <div><Header title="App-User Location History" />
            <MainMenu menu="appusers" />

            {/* <div class="app-content content"> */}
            <div class=" content">
                <div class="content-wrapper" style={{ backgroundColor: "azure" }} >

                    <div class="content-body">

                        <div class="row justify-content-md-center">
                            <div class="col-xl-12  col-12">
                                <section id="horizontal-vertical">
                                    <div class="row">
                                        <div class="col-12">

                                            {(() => {
                                                if (loading) {
                                                    if (userLocationHistory && userLocationHistory.length > 0) {

                                                        return <div class="card">
                                                            <div class="card-content collapse show">
                                                                <div class="card-body card-dashboard">

                                                                    <div class="row">
                                                                        <div class="col-sm-5"><div class="h3 font-weight-bold">{user.user_name} - App User Location History</div>
                                                                        </div>
                                                                        {/* <div class="col-sm-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search by Device Name or Box ID" onChange={e => setQuery(e.target.value)} />
                                                                        </div> */}
                                                                        <div class="col-sm-2">
                                                                            <button class="btn btn-success pull-right" onClick={exportDevices}>Download</button>
                                                                        </div>

                                                                    </div>
                                                                    {/* <a class="btn btn-primary" href="/device/create">Create New</a> */}

                                                                    <div class="table-responsive" style={{ maxHeight: '400px', minHeight: '500px' }}>
                                                                        <table class="table display nowrap table-striped table-bordered">
                                                                            <thead class="sticky-top" style={{ backgroundColor: '#4cbfff' }}>
                                                                                <tr>
                                                                                    <th>Log ID</th>
                                                                                    <th>Created At</th>
                                                                                    <th>Latitude</th>
                                                                                    <th>Longitude</th>
                                                                                    {/* <th>Address (ref: online)</th> */}

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                {userLocationHistory?.map((userLocation) =>
                                                                                    <tr key={userLocation.id}>
                                                                                        <td>{userLocation.id}</td>
                                                                                        <td>{userLocation.title}</td>
                                                                                        <td>{userLocation.lat}</td>
                                                                                        <td>{userLocation.lng}</td>
                                                                                        {/* <td>{userLocation.address ?? ''}</td> */}

                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    }
                                                } else {
                                                    return <div class="card">
                                                        <div class="card-content collapse show">
                                                            <div class="card-body card-dashboard">

                                                                <div class="row">
                                                                    <div class="col-md-7"><div class="h3 font-weight-bold">{user.user_name} - App User Location</div></div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />

                                                            </div>
                                                        </div>

                                                    </div>

                                                }



                                            })()}


                                        </div>
                                    </div>
                                </section >

                            </div>
                        </div>

                        <br />
                        <br />


                        <div class="row justify-content-md-center">
                            <div class="col">


                                {(() => {
                                    if (loading) {
                                        if (userLocationHistory && userLocationHistory.length > 0) {

                                            return <div class="card">
                                                <div class="card-header">
                                                    <div class="row"><div class="col-11 h2 card-title font-weight-bold">{user.user_name} - App User Location</div><div class="row col card-title align-items-right"></div></div>

                                                </div>
                                                <div class="card-content collapse show" style={{ height: "15em !important" }}>


                                                    <LiveMap points={userLocationHistory} />


                                                </div>
                                            </div>



                                        }
                                    } else {
                                        return <div class="card">
                                            <div class="card-content collapse show">
                                                <div class="card-body card-dashboard">

                                                    <div class="row">
                                                        <div class="col-md-7"><div class="h3 font-weight-bold">{user.user_name} - App User Location</div></div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />

                                                </div>
                                            </div>

                                        </div>

                                    }



                                })()}



                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    )
}
export default MapLocation;