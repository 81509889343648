import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import DeployerForm from './DeployerForm';

const AddDeployer = () => {
    return (
        <div>
            <DeployerForm mode="Create" />
        </div>
    )
}
export default AddDeployer;