import { useState, useEffect } from "react";
import axiosConfig from '../axiosConfig';
import Header from "../Header/Header";
import MainMenu from "../MainMenu/MainMenu";
import Dashboard from "../Dashboard/Dashboard";
import * as XLSX from 'xlsx';
import { set } from "date-fns";

const DataCleanse = () => {
    const [cleans, setCleans] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [Cleanable, setCleanable] = useState("");
    const [viewLog, setViewLog] = useState({});
    const [logs, setLogs] = useState([]);
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const [excelFileSuccess, setExcelFileSuccess] = useState(null);

    console.log(excelFile);

    const hours = new Date().getHours();
    const mins = new Date().getMinutes();
    
    useEffect(() => {
        axiosConfig.get("/lastCleanedDate").then((rsp) => {
            //alert(rsp.data.lastUpdatedDate);
            console.log(rsp.data);
            //setCleans(rsp.data.data);
            var obj={ date:getNextDay(rsp.data.lastCleanedDate) };
            setCleans([obj]);
            setCleanable(getNextDay(rsp.data.lastCleanedDate));
            setLastUpdatedDate(rsp.data.lastCleanedDate);

        }, (err) => { });


    }, []);
    function getNextDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    const CleaDataDate = (id) => {
        axiosConfig.get("/cleaning/data/date/" + id).then((rsp) => {

            //setCleans(rsp.data.data);
            setLastUpdatedDate(rsp.data.lastUpdatedDate);


        }, (err) => { });
    };
    const CleaNByDate = () => {
        //alert(Cleanable);
        setLoading(true);
        axiosConfig.get("/dataclense_byDate/" + Cleanable).then((rsp) => {
        alert("Data Cleaned Successfully");
        window.location.reload();
            


        }, (err) => { });
    };

    const DashboardGraphGenerate = (date) => {
        axiosConfig.get("/dashboard/graphGenerate/byDate/" + date).then((rsp) => {


        }, (err) => { });
    };



    const Search = (e) => {
        var data = e.target.value;
        axiosConfig.get("/viewlog/" + data).then((rsp) => {

            setViewLog(rsp.data);


        }, (err) => { });
    };

    const CleaData = (id) => {
        axiosConfig.get("/clean/data/" + id).then((rsp) => {
            
            if(rsp.data=="Already Cleaned"){
                alert("Already Cleaned");
            }else{
                setViewLog({});
            }

            setViewLog({});


        }, (err) => { });
    };

    console.log(new Date().getHours());


    //Excel/CSV file upload operation

    const fileTypes=['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile=(e)=>{
        
        let selectedFile=e.target.files[0];
        if(selectedFile){
            if(fileTypes.includes(selectedFile.type)){
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload=(e)=>{
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                    setExcelFileSuccess(null);
                };
                
                
            }else{
                setExcelFileError('Please select valid excel file');
                setExcelFile(null);
                setExcelFileSuccess(null);
            }
        }else{
            setExcelFileError('Please select your file');
            setExcelFile(null);
            setExcelFileSuccess(null);
        }
    }
    function isDateLessThanToday(dateString) {
        // Convert the string to a Date object
        const inputDate = new Date(dateString);
        
        // Get today's date (without time part)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to 00:00:00
        
        // Compare the dates
        return inputDate < today;
      }
    //submit excel file
    const handleSubmit=(e)=>{
        e.preventDefault();
        if(excelFile){
            const workbook = XLSX.read(excelFile,{type:'buffer'});
            const workSheetName = workbook.SheetNames[0];
            const workSheet = workbook.Sheets[workSheetName];
            const data = XLSX.utils.sheet_to_json(workSheet);
            // Extract the values of the first column into an array
            //const firstColumnValues = data.map((row) => row['viewlog_id']); // Replace 'Column1' with the actual header name of the first column
            const firstColumnValues = data.map((row) => row[Object.keys(row)[0]]);

            console.log(firstColumnValues.length);

            if(firstColumnValues && firstColumnValues.length > 0){
                for (let i = 0; i < firstColumnValues.length; i++) {
                    console.log(firstColumnValues[i]);
                    axiosConfig.get("/clean/data/" + firstColumnValues[i]).then((rsp) => {
                    }, (err) => { });
                }
            }

            setExcelFile(null);
            setExcelFileError(null);
            setExcelFileSuccess('Viewlog Updated Successfully');

            
        }else{
            setExcelFileError('Please select your file');
            setExcelFileSuccess(null);
        }
    }





    return (

        <div><Header title="Data Cleansing" />
            <MainMenu menu="datacleansing" />
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper" style={{ backgroundColor: "azure" }} >
                    <div class="content-header row">
                    </div>
                    <div class="content-body">
                        <div class="row">
                            <div class="col-md-5"><div class="h3 text-center font-weight-bold">Cleansing Status</div>
                                <div class="h6 text-center ">Last Cleaned at {lastUpdatedDate}</div></div>
                            <div className="col-md-3"><div class="h3 text-center font-weight-bold">Viewlogs</div></div>
                            <div class="col-md-4"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search with ViewLog Id" onChange={e => Search(e)} />
                            </div>

                        </div>

                        {cleans?.map((c) =>
                            <div class="row">
                                <div className="col-md-5">
                                    <div class="table-responsive" style={{ maxHeight: '400px', minHeight: '500px' }}>
                                        <table class="table display nowrap table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Status</th>

                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr>
                                                    <td>{c.date}</td>
                                                    <td>Raw</td>

                                                    <td>
                                                    {!loading&& <button onClick={() => CleaNByDate()} disabled={(!isDateLessThanToday(Cleanable))||loading} className="btn btn-danger">Proceed</button>}
                                                        {loading&&<div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>}
                                                    
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div class="table-responsive" style={{ maxHeight: '200px', minHeight: '300px' }}>
                                        <table class="table display nowrap table-striped table-bordered">
                                            <thead>
                                                <tr><th>Log Id</th>
                                                    <th>Channel Id</th>
                                                    <th>User Id</th>
                                                    <th>started</th>
                                                    <th>finished</th>

                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <td>{viewLog.id}</td>
                                                <td>{viewLog.channel_id}</td>
                                                <td>{viewLog.user_id}</td>
                                                <td>{viewLog.started_watching_at}</td>
                                                <td>{viewLog.finished_watching_at}</td>
                                                <td><button  onClick={e => CleaData(viewLog.id)} className="btn btn-danger">Clean</button></td>
                                            </tbody>
                                        </table>
                                    </div>



                                    <div class="card">
                                        <div class="card-header">Viewlog ID List</div>
                                        <div class="card-content">
                                            <div class="card-body">
                                                {/* upload file section */}
                                                
                                                    <div className='form'>
                                                        <form className='form-group' autoComplete="off" onSubmit={handleSubmit}>
                                                        <label><h5>Upload Excel file</h5></label>
                                                        <br></br>
                                                        <input type='file' className='form-control' onChange={handleFile} required></input>                  
                                                        {excelFileError&&<div className='text-danger' style={{marginTop:5+'px'}}>{excelFileError}</div>}
                                                        {excelFileSuccess&&<div className='text-success' style={{marginTop:5+'px'}}>{excelFileSuccess}</div>}
                                                        <button type='submit' className='btn btn-success' style={{marginTop:5+'px'}}>Submit</button>
                                                        </form>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )

}

export default DataCleanse;