
import { useState, useEffect } from "react";
import axiosConfig from '../axiosConfig';
import Header from "../Header/Header";
import MainMenu from "../MainMenu/MainMenu";
import PopUpDetails from "./PopUpDetails";




const DeviceViewingStatusList = () => {

    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");
    useEffect(() => {
        // setLoading(false);
        console.log("useEffect");
        axiosConfig.get("/device-viewing-status/list").then((rsp) => {
            setDevices(rsp.data);
            console.log(rsp.data[0]);
            setLoading(true);
        }, (err) => {
            setLoading(true);
        });


    }, []);



    const Search = (data) => {
        return data.filter(
            (item) =>
                item.device_name.toLowerCase().includes(query.toLowerCase()) ||
                item.deviceBoxId && item.deviceBoxId.toString().includes(query)
        );
    };



    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    var getCSV = (scsv) => {
        exportToCsv("Device_Viewing_Status" + new Date().toLocaleString() + ".csv", scsv)
    }

    const exportDevices = () => {
        //console.log(liveChannelData.labels[0]);
        var csv = [["Household ID", "Household Name", "Household Last Active At", "Box Id", "Box Last Active At", "Connected At", "Last Req. From Viewlog", "Days Since Last Viewlog", "Last Req. From DeselectLog", "Days Since Last DeselectLog", "Selection Status", "User Count", "Economic Status"]];

        for (var i = 0; i < devices.length; i++) {
            csv.push([
                devices[i].id,
                devices[i].device_name,
                devices[i].last_request,
                devices[i].deviceBoxId ?? "Box Not Connected",
                devices[i].deviceBoxLastRequest,
                devices[i].deviceBoxId ? devices[i].connected_at : '',
                devices[i].last_request_fromViewlog,
                devices[i].last_request_fromViewlog ? (devices[i].days_since_last_viewlog ?? '') : 'N/A',
                devices[i].last_request_fromDeselectlog,
                devices[i].last_request_fromDeselectlog ? (devices[i].days_since_last_deselectlog ?? '') : 'N/A',
                devices[i].deselect,
                devices[i].userCount,
                devices[i].economic_status,

            ]);


        }
        console.log(csv);
        getCSV(csv);
    }



    return (
        <div><Header title="Devices Viewing Status" />
            <MainMenu menu="deviceViewingStatus" />
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper" style={{ backgroundColor: "azure" }} >
                    <div class="content-header row">
                    </div>
                    <div class="content-body">



                        <div class="row justify-content-md-center">
                            <div class="col-xl-12  col-12">
                                <section id="horizontal-vertical">
                                    <div class="row">
                                        <div class="col-12">

                                            {(() => {
                                                if (loading) {
                                                    if (devices && devices.length > 0) {

                                                        return <div class="card">
                                                            <div class="card-content collapse show">
                                                                <div class="card-body card-dashboard">

                                                                    <div class="row">
                                                                        <div class="col-sm-5"><div class="h3 font-weight-bold">Device Viewing Status List</div>
                                                                        </div>
                                                                        <div class="col-sm-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search by Device Name or Box ID" onChange={e => setQuery(e.target.value)} />
                                                                        </div>
                                                                        <div class="col-sm-2">
                                                                            <button class="btn btn-success pull-right" onClick={exportDevices}>Export</button>
                                                                        </div>

                                                                    </div>
                                                                    {/* <a class="btn btn-primary" href="/device/create">Create New</a> */}

                                                                    <div class="table-responsive" style={{ maxHeight: '400px', minHeight: '500px' }}>
                                                                        <table class="table display nowrap table-striped table-bordered">
                                                                            <thead class="sticky-top" style={{ backgroundColor: '#4cbfff' }}>
                                                                                <tr>
                                                                                    <th>Household ID</th>
                                                                                    <th>Household Name</th>
                                                                                    <th>Household Last Active At </th>
                                                                                    <th>Box Id</th>
                                                                                    <th>Box Last Active At</th>
                                                                                    <th>Connected At</th>
                                                                                    <th>Last Req. From Viewlog</th>
                                                                                    <th>Days Since Last Viewlog</th>
                                                                                    <th>Last Req. From DeselectLog</th>
                                                                                    <th>Days Since Last DeselectLog</th>
                                                                                    <th>Users(Count) under Device</th>
                                                                                    <th>Selection Status</th>
                                                                                    <th>Economic Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style={{ textAlign: 'center' }}>
                                                                                {Search(devices).map((device) =>
                                                                                    <tr key={device.id}>
                                                                                        <td>{device.id}</td>
                                                                                        <td>{device.device_name}</td>
                                                                                        <td>{device.last_request ?? ''}</td>
                                                                                        <td>
                                                                                            {device.deviceBoxId ? (
                                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                    <span>{device.deviceBoxId}</span>
                                                                                                    <a
                                                                                                        href={"/box/history/"+device.deviceBoxId}
                                                                                                        className="btn btn-info btn-sm p-0 ml-1"
                                                                                                        style={{ width: '24px', height: '24px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
                                                                                                        role="button"
                                                                                                    >
                                                                                                        <i className="la la-history" style={{ fontSize: '12px' }}></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <span className="text-danger">Box Not Connected</span>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>{device.deviceBoxLastRequest ?? ''}</td>
                                                                                        <td>{device.connected_at ?? ''}</td>
                                                                                        <td>{device.last_request_fromViewlog ?? ''}</td>
                                                                                        <td>{device.last_request_fromViewlog ? (device.days_since_last_viewlog ?? '') : 'N/A'}</td>
                                                                                        <td>{device.last_request_fromDeselectlog ?? ''}</td>
                                                                                        <td>{device.last_request_fromDeselectlog ? (device.days_since_last_deselectlog ?? '') : 'N/A'}</td>
                                                                                        <td>{device.userCount}</td>
                                                                                        <td>{device.deselect ?? ''}</td>
                                                                                        <td>{device.economic_status ?? ''}</td>

                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    }
                                                } else {
                                                    return <div class="card">
                                                        <div class="card-content collapse show">
                                                            <div class="card-body card-dashboard">

                                                                <div class="row">
                                                                    <div class="col-md-7"><div class="h3 font-weight-bold">Device Viewing Status</div></div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />

                                                            </div>
                                                        </div>

                                                    </div>

                                                }



                                            })()}




                                        </div>
                                    </div>
                                </section >





                            </div>
                        </div>


                        <br />



                        <br />


                    </div>
                </div>

            </div>
        </div>
    )

}
export default DeviceViewingStatusList;