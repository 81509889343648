
import { BrowserRouter, Routes, Route,Navigate} from "react-router-dom";
import Header from '../Header/Header';

import Cookies from 'universal-cookie';
import MainMenu from '../MainMenu/MainMenu';
import AdLogUpload from '../LogUpload/AdLogUpload';
import ProgramLogUpload from '../LogUpload/ProgramLogUpload';
import Footer from "../Footer/Footer";



function Logger() {

  const cookies = new Cookies();
  return (
    <div>
    
    
    <BrowserRouter>
    <MainMenu />
      <Routes>
         
      <Route exact path="/" element={<ProgramLogUpload />}></Route>
          <Route exact path="/ProgramLogUpload" element={<ProgramLogUpload />}></Route>
          <Route exact path="/AdLogUpload" element={<AdLogUpload />}></Route>
        
        {/* <Navigate to="/" /> */}
        <Route
        path="*"
        element={<Navigate to="/" replace />}
    />
        {/* <Route exact path="/map" element={<Map />}></Route> */}
      </Routes>
    </BrowserRouter>
    <Footer/>
    </div>
    );
}

export default Logger;