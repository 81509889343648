import React, { useState } from "react";
import axiosConfig from "../axiosConfig";
import Header from "../Header/Header";
import MainMenu from "../MainMenu/MainMenu";

const ViewlogRawData = () => {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axiosConfig.post("/viewlog-raw-data", {
        first_date: firstDate,
        last_date: lastDate,
      });
      setLogs(response.data);
    } catch (error) {
      console.error("Error retrieving logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportToCsv = (filename, rows) => {
    var processRow = function (row) {
      var finalVal = "";
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ",";
        finalVal += result;
      }
      return finalVal + "\n";
    };
    var csvFile = "";
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }
    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleExport = () => {
    const csv = [
      [
        "id",
        "channel_id",
        "user_id",
        "user_name",
        "started_watching_at",
        "finished_watching_at",      
        "duration_minute",
        "system",
        "universe",
        "category_id",
        
      ],
      ...logs.map((log) => [
        log.id,
        log.channel_id,
        log.user_id,
        log.user_name,
        log.started_watching_at,
        log.finished_watching_at,
        log.duration_minute,
        log.system,
        log.universe,
        log.category_id,
        
      ]),
    ];
    exportToCsv(
      `View_Log_Raw_Data_${new Date().toLocaleString()}.csv`,
      csv
    );
  };

  return (
    <div>
      <Header title="View Log Raw Data" />
      <MainMenu menu="" />
      <div
        className="app-content content"
        style={{ backgroundColor: "azure", minHeight: "36em" }}
      >
        <div className="content-overlay"></div>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="row justify-content-md-center">
              <div className="col-xl-12 col-12">
                <section id="horizontal-vertical">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-content collapse show">
                          <div className="card-body card-dashboard">
                            <div>
                              <h2 style={{ textAlign: "center" }}>
                                View Log Raw Data
                              </h2>
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-4">
                                    <label htmlFor="firstDate">
                                      <b>First Date:</b>
                                    </label>
                                    <input
                                      type="date"
                                      id="firstDate"
                                      className="form-control"
                                      value={firstDate}
                                      onChange={(e) =>
                                        setFirstDate(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label htmlFor="lastDate">
                                      <b>Last Date:</b>
                                    </label>
                                    <input
                                      type="date"
                                      id="lastDate"
                                      className="form-control"
                                      value={lastDate}
                                      onChange={(e) =>
                                        setLastDate(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  <div className="col-md-2">
                                  <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={handleExport}
                                      >
                                        CSV
                                      </button>
                                  </div>                                 
                                </div>
                              </form>
                            </div>
                            <div className="table-responsive">
                              <table className="table">
                                <thead class="sticky-top" style={{ backgroundColor: '#4cbfff' }}>
                                  <tr>
                                    <th>id</th>
                                    <th>Channel Id</th>
                                    <th>User Id</th>
                                    <th>User Name</th>
                                    <th>Started Watching at</th>
                                    <th>Finished Watching at</th>
                                    <th>Duration Minute</th>
                                    <th>System</th>
                                    <th>Universe</th>
                                    <th>Category Id</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading ? (
                                    <tr>
                                      <td colSpan="10"><b style={{ backgroundColor: '#4cbfff' }}>Loading...</b></td>
                                    </tr>
                                  ) : (
                                    logs.map((log) => (
                                      <tr key={log.id}>
                                        <td>{log.id}</td>
                                        <td>{log.channel_id}</td>
                                        <td>{log.user_id}</td>
                                        <td>{log.user_name}</td>
                                        <td>{log.started_watching_at}</td>
                                        <td>{log.finished_watching_at}</td>
                                        <td>{log.duration_minute}</td>
                                        <td>{log.system}</td>
                                        <td>{log.universe}</td>
                                        <td>{log.category_id}</td>
                                      
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewlogRawData;
