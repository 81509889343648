import React from 'react';
import { useState, useEffect } from "react"
import * as xlsx from "xlsx";
import Header from "../Header/Header";
import HeaderLogger from "../Header/HeaderLogger";
import axiosConfig from "../axiosConfig";
import axiosConfigDotNet from "../axiosConfigDotNet";
import Select from 'react-select';
import Cookies from 'universal-cookie';

import MainMenu from '../MainMenu/MainMenu';
var range;
var wholejson;
var comm;
const AdLogUpload = () => {
  const cookies = new Cookies();
  const [loading, setloading] = useState(true);
  const [updater, setupdater] = useState(0);
  const [ranges, setranges] = useState([]);
  
  const [channels, setchannels] = useState([]);

  useEffect(() => {

    axiosConfig.get("/channels").then(rsp => {
      //console.log(rsp.data);
      setchannels(rsp.data.channels);
      //console.log(channels);


    }).catch(err => {

    })

  }, [])

function isValidTimeFormat(inputString) {
  // Define the regular expression for the "HH:mm:ss" format
  var timeFormatRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

  // Test if the input string matches the regular expression
  return timeFormatRegex.test(inputString);
}

  const IncrementCount = () => {
    // Update state with incremented value
    setupdater(updater + 1);
  }
  useEffect(() => {
    if (updater > 0) {
      document.querySelector("#excelld").style.display = "none";
      document.querySelector("#loading").style.display = "block";
      var data = {
        
        data:range
      }
      //console.log(data.id);
      //console.log(updater);
      console.log(JSON.stringify(data));


      axiosConfigDotNet.post("/logupload/adlog", data).then(rsp => {
        alert("Data Succesfully Inserted");
        document.querySelector("#loading").style.display = "none";
        //console.log("done");
      }).catch(err => {
        alert("Server Error");
      });

      // axiosConfigDotNet.post("/adtrpv3/reachp", data).then(rsp => {
      //   setreachpf(true);
      //   setreachp(rsp.data.value);
      //   console.log(reachp);
      //   console.log("done");
      //   setbadf(false);
      // }).catch(err => {

      //   setbadf(true);
      // });

    }
  }, [updater]);

  
  function getIdFromChannelName(channelName) {
    const channel = channels.find(item => item.channelName === channelName);
    return channel ? channel.id : null;
  }
  function isValidDateFormat(inputString) {
    // Define the regular expression for the "yyyy-mm-dd hh:mm:ss" format
    var dateFormatRegex = /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}$/;

    // Test if the input string matches the regular expression
    return dateFormatRegex.test(inputString);
}

  const ReadUploadFile = (e) => {

    e.preventDefault();
    if (e.target.files) {
      const Reader = new FileReader();
      Reader.onload = (e) => {
        document.querySelector("#excelld").style.display = "none";
        document.querySelector("#loading").style.display = "block";
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });//
        
        var arr = [];
        for (var i = 0; i < json.length; i++) {
    
          if(
             json[i].Ad_Date && json[i].Company && json[i].Ad_Type  && json[i].Peak  && json[i].duration  && 
            isValidDateFormat(json[i].start) && isValidDateFormat(json[i].finish) && isValidTimeFormat(json[i].Telecast_Time)  && json[i].Ad_Name && json[i].Brand  && json[i].Sub_Brand && json[i].Product_Type && json[i].Product && json[i].Program_Type && json[i].Program_Name 
            && json[i].Break_Type && json[i].Ad_Qty && json[i].Ad_Pos && json[i].ad_price
          ){
            
          var obj={
            AdDate:json[i].Ad_Date,
            ChannelId:getIdFromChannelName(json[i]['Channel Name']),
            Company:json[i].Company,
            AdType:json[i].Ad_Type,
            Peak:json[i].Peak,
            TelecastTime:json[i].Telecast_Time,
            Duration:json[i].duration,
            Start:json[i].start,
            Finish:json[i].finish,
            AdName:json[i].Ad_Name,
            Brand:json[i].Brand,
            SubBrand:json[i].Sub_Brand,
            ProductType:json[i].Product_Type,
            Product:json[i].Product,
            ProgramType:json[i].Program_Type,
            ProgramName:json[i].Program_Name,
            BreakType:json[i].Break_Type,
            AdQty:json[i].Ad_Qty,
            AdPos:json[i].Ad_Pos,
            AdPrice:json[i].ad_price,
            //campaign:json[i].campaign,
            //incomplete_ad:json[i].incomplete_ad
            }
            if(json[i].Campaign){
                obj.Campaign=json[i].Campaign;
                }
            if(json[i].incomplete_ad){
                obj.IncompleteAd=json[i].incomplete_ad;
                }
          arr.push(obj);
          //console.log(obj);
          }
          else{
            alert("Some data has faulty properties, Check row no "+(i+2)+" and try again");
            
        document.querySelector("#loading").style.display = "none";
        //document.querySelector("#excelld").style.display = "block";
            break;
          }
        }
        //console.log(arr[780]);
        range = arr;
        wholejson = json;
        //GetData(arr);
        if(json.length==arr.length){
          
        document.querySelector("#loading").style.display = "none";
        document.querySelector("#excelld").style.display = "block";
        }

      };
      Reader.readAsArrayBuffer(e.target.files[0]);
    }

  }


  return (
    <div>
    {
      (cookies.get('_role') != "logger") &&<Header title="Ad Log Upload" />
    }
    {
      (cookies.get('_role') == "logger") &&<HeaderLogger title="Ad Log Upload" />
    }
      <MainMenu menu="adlogupload" />

      <div class="app-content content"  style={{ backgroundColor: "azure", minHeight: "36em" }}>
        <div class="content-overlay"></div>
        <div class="content-wrapper" style={{ backgroundColor: "azure" }} >
          <div class="content-header row">
          </div>
          <div class="content-body">


            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <h1>Ad Log Upload:</h1>

                  
                  <br/>
                  <br/>
                  <br/>
                  <h1>Upload excel</h1>
                  <form>
                    <label htmlFor="upload">Upload File</label>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      onChange={ReadUploadFile}
                    />


                  </form>
                  <div class="text-left">
                    

                    <div id="excelld" style={{ display: "none" }}>
                      <div class="font-weight-bold" >Excel loaded and processed</div>
                      <br />
                      <button onClick={IncrementCount} class="btn btn-info">Upload Ad Log</button>
                    </div>
                    <div id="loading" style={{ display: "none", height:"10em"}}>
                      <div class="font-weight-bold" >Excel loaded and processed</div>
                      <br />
                      <img  style={{height:"5em"}}src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" alt="..." />&nbsp;&nbsp;Please Wait...
                    </div>
                    <br />
                    {/* {(() => {
                      if (!badf) {// tvr0f && tvrpf &&
                        return <button onClick={console.log("clicked")} class="btn btn-danger">Download CSV</button>


                      } else {
                        return

                      }
                    })()} */}
                    
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}
export default AdLogUpload;