import { useState, useEffect } from 'react';
import axiosConfig from '../axiosConfig';
import Cookies from 'universal-cookie';

const Login = () => {
    const cookies = new Cookies();
    const [uname, setUname] = useState("");
    const [pass, setPass] = useState("");
    const [err, setErr] = useState({});


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    useEffect(() => {

        // Ask for geolocation permission 
        navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        
                if (result.state === "granted") {
                    console.log(result.state);
                    
                } else if (result.state === "denied") {
                    // Handle the case where geolocation permission is denied
                    console.log(result.state);
                    alert("Please enable location from your browser settings to get the full functionality of the website.");
                } else if (result.state === "prompt") {
                    // Handle the case where the browser prompts for geolocation permission
                    console.log(result.state);
                    alert("Please grant location access permission for full website functionality. Thank you!");
                }
            });



    }, []);

    const handleForm = (e) => {
        e.preventDefault();



        navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position);
            //console.log("Latitude is :", position.coords.latitude);
            //console.log("Longitude is :", position.coords.longitude);
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            const obj = { username: uname, password: pass, latitude: position.coords.latitude, longitude: position.coords.longitude };
            console.log(obj);
            axiosConfig.post("/auth/sign-in", obj).then((rsp) => {
                console.log(rsp.data);
                cookies.set('_authToken', rsp.data.data.token);
                cookies.set('_role', rsp.data.data.role);
                cookies.set('username', rsp.data.data.username);
                window.location.href = "/";
            }, (err) => {
                if (err.response.status === 422) {
                    console.log(err.response.data);
                    setErr(err.response.data);
                } else if (err.response.status === 423) {
                    window.location.href = "/account/notice";
                }
            });

        }, function (error) {
            //console.error("Error Code = " + error.code + " - " + error.message);
            if (error.code === 1) {
                alert("Please grant location access permission for full website functionality. Thank you!");
            } else if (error.code === 2) {
                alert("Location information is unavailable. Please try again.");
            } else if (error.code === 3) {
                alert("The location access permission timed out. Please try again.");
            } else if (error.code === 4) {
                alert("An unknown error occurred.. Please try again.");
            }
        }, { enableHighAccuracy: true, timeout: 5000, maximumAge: 0});
    }

    return (

        <div class="content-wrapper">
            <div class="row justify-content-center">
                <div class="content-body col-md-4">
                    <div class="card border-grey border-lighten-3 m-0">
                        <div class="card-header border-0">
                            <div class="card-title text-center">
                                <div class="p-1"><img src="/app-assets/images/logo/bscl-logo.png" alt="branding logo" /></div>
                            </div>
                            <h6 className="card-subtitle line-on-side text-muted text-center font-medium-5 pt-2"><span>Login</span>
                            </h6>
                        </div>
                        <div class="card-content">
                            <div class="card-body">



                                {/* location access permission should give message with an location icon */}
                                <div className="d-flex align-items-center mb-3">
                                    <i className="la la-map-marker"></i>
                                    <span className="ml-2" style={{ color: 'red' }}>Please give location access permission.</span>
                                </div>
                                {/* ... rest of your code ... */}



                                <form onSubmit={handleForm} class="form-horizontal form-simple" noValidate>
                                    <h6 class="text-center font-weight-bold text-danger">{err.error}</h6>
                                    <h6 class="text-center font-weight-bold text-danger">{err.latitude ? err.latitude[0] : ''}</h6>
                                    <fieldset class="form-group position-relative has-icon-left mb-0">
                                        <input type="text" class="form-control" id="user-name" onChange={(e) => { setUname(e.target.value) }} value={uname} placeholder="Your Username" required data-validation-required-message="Please enter username."/>
                                        <div class="form-control-position">
                                            <i class="la la-user"></i>
                                        </div>
                                        <div className="help-block font-small-3" />
                                        <span class="text-danger">{err.username ? err.username[0] : ''}</span>
                                    </fieldset>
                                    <fieldset class="form-group position-relative has-icon-left">
                                        <input type="password" class="form-control" id="user-password" onChange={(e) => { setPass(e.target.value) }} value={pass} placeholder="Enter Password" required data-validation-required-message="Please enter password."/>
                                        <div class="form-control-position">
                                            <i class="la la-key"></i>
                                        </div>
                                        <div className="help-block font-small-3" />
                                        <span class="text-danger">{err.password ? err.password[0] : ''}</span>
                                    </fieldset>
                                    <div class="form-group row">
                                        <div class="col-sm-6 col-12 text-center text-sm-left">
                                            <fieldset>
                                                <input type="checkbox" id="remember-me" class="chk-remember" />
                                                <label for="remember-me"> Remember Me</label>
                                            </fieldset>
                                        </div>
                                        <div class="col-sm-6 col-12 text-center text-sm-right"><a href="/forget-Pass/email" class="card-link">Forgot Password?</a></div>
                                    </div>
                                    <button type="submit" class="btn btn-info btn-block"><i class="ft-unlock"></i> Login</button>
                                </form>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="">
                                <p class="float-xl-left text-center m-0"><a href="/forget-Pass/email" class="card-link">Recover
                                    password</a></p>
                                <p class="float-xl-right text-center m-0">New to BSCL User? <a href="/" class="card-link">Suggesstion</a></p> <br />
                                <p class="float-xl-right text-center m-0">Deployer <a href="/deployer/validation" class="card-link">Registration Form</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Login;